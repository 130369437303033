import { lazy, Fragment } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { ApmRoute } from '@elastic/apm-rum-react';
import TripSettingsView from 'src/views/TripSettingsView';
import MainLayout from 'src/layouts/MainLayout';
import TripView from 'src/views/TripView';
import { RouteType } from './types';
import Login from '../components/Login';
import { DeleteAccountView } from '../views/DeleteAccountView';

const routesConfig = [
  {
    exact: true,
    path: '/',
    component: () => <Redirect to="/trip" />
  },
  {
    exact: true,
    path: '/login',
    component: () => <Login />
  },
  {
    exact: true,
    path: '/map',
    component: lazy(() => import('src/views/MapView'))
  },
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/errors/Error404View'))
  },
  {
    exact: true,
    path: '/signin-callback',
    component: lazy(() => import('src/views/auth/Callback'))
  },
  {  
    exact: true,
    path: '/delete-account',
    component: DeleteAccountView
  },
  {
    exact: true,
    path: '/delete-account',
    component: DeleteAccountView
  },
  {
    path: '*',
    layout: MainLayout,
    routes: [
      {
        exact: true,
        path: '/trip',
        component: TripView
      },
      {
        exact: true,
        path: '/trip-settings',
        component: TripSettingsView
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  }
] as RouteType[];

const renderRoutes = (routes: RouteType[]) =>
  routes ? (
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <ApmRoute
            key={i} // eslint-disable-line react/no-array-index-key
            path={route.path}
            exact={route.exact}
            component={(props: any) => (
              <Guard>
                <Layout>
                  {route.routes ? (
                    renderRoutes(route.routes)
                  ) : (
                    <Component {...props} />
                  )}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  ) : null;

function Routes() {
  return renderRoutes(routesConfig);
}

export default Routes;
